/* General */

.landing h1, h2, h3, h4, h5, h6 {
    /*color: #638a9d;*/
    margin-top: 0;
    margin-bottom: 0;
    line-height: 1.23;
}

.landing h1 {
    font-size: 56px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .landing h1 {
    font-size: 46px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .landing h1 {
    font-size: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .landing h1 {
    font-size: 34px;
  }
}

.landing h2 {
  font-size: 48px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .landing h2 {
    font-size: 36px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .landing h2 {
    font-size: 36px;
  }
}

@media only screen and (max-width: 767px) {
  .landing h2 {
    font-size: 30px;
  }
}

.landing h3 {
  font-size: 40px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .landing h3 {
    font-size: 32px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .landing h3 {
    font-size: 28px;
  }
}

@media only screen and (max-width: 767px) {
  .landing h3 {
    font-size: 25px;
  }
}

.landing h4 {
  font-size: 34px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .landing h4 {
    font-size: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .landing h4 {
    font-size: 28px;
  }
}

@media only screen and (max-width: 767px) {
  .landing h4 {
    font-size: 24px;
  }
}

.landing h5 {
  font-size: 24px;
}

.landing h6 {
  font-size: 18px;
}

.landing p {
    font-size: 16px !important;
}

.landing hr.hrGray {
    margin: 5px 0 !important;
    border: .5px solid gray !important;
    background-color: gray !important;
    width: 90%;
}

.landing .imgEcommerces img {
    width: 80% !important;
    height: auto;
    margin-left: 10%;
}

.landing .textEllispsis {
    font-weight: normal;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.landing .title {
    color: #3F444F !important;
    font-weight: lighter !important;
}

.landing .root {
    display: flex !important;
    /* margin-top: 20px; */
}

.landing .menu {
    margin-top: 30px;
    z-index: 999;
}

.landing .centerContent {
    text-align: center;
}

.landing .d-grid {
    display: grid;
    justify-items: center;
}

.landing .zindex_1 {
    z-index: 1 !important;
}

.landing .positionRelative {
    position: relative !important;
}

.landing div.flexStart {
    display: flex !important;
    align-items: center !important;
    justify-items: flex-start !important;
}

.landing div.flexEnd {
    display: flex !important;
    align-items: center !important;
    justify-content: flex-end !important;
}

.landing .bgWhite {
    background-color: #F5F5F5 !important;
}

.landing .mb_4 {
    margin-bottom: 20px !important;
}

.landing .mY_10 {
    margin: 50px 0 !important;
}

.landing .mY_Special {
    /* margin: -40px 0 40px 0 !important; */
}

.landing .p_4 {
    padding: 25px !important;
}


/* Button Orange */

.landing .btnOrange {
    background-color: #E9581E !important;
    color: white !important;
    letter-spacing: 5px !important;
    font-weight: normal !important;
    font-size: 12px !important;
    padding: 6px 25px !important;
}

.landing .btnOrange:hover {
    background-color: rgb(202, 76, 27) !important;
}

.landing .btnOrange a {
    color: white !important;
    letter-spacing: 5px !important;
    font-weight: normal !important;
    font-size: 12px !important;
    text-decoration: none;
}

.landing a.linkWhite {
    color: white !important;
    letter-spacing: 5px !important;
    font-weight: normal !important;
    font-size: 14px !important;
    text-decoration: none;
    background-color: transparent;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
}

.landing a.linkWhite:hover {
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
    background-color: transparent;
    color: white !important;
    letter-spacing: 5px !important;
    font-weight: bold !important;
    font-size: 14px !important;
    text-decoration: none;
    cursor: pointer;
}

.landing .btnOrange_border {
    background-color: white !important;
    color: rgb(130, 130, 130) !important;
    border: rgb(130, 130, 130) 1px solid !important;
    letter-spacing: 3px !important;
    font-weight: normal !important;
    font-size: 12px !important;
    padding: 6px 25px !important;
    transition: all .3s !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    min-width: 90%;
    width: fit-content !important;
    width: -moz-fill-available !important;
    width: -webkit-fill-available !important;
    margin: 0px 10px !important;
    padding: 5px 10px !important;
}

.landing .btnOrange_border:hover {
    color: #E9581E !important;
    border: #E9581E 1px solid !important;
}

.landing .btnOrange_border.active {
    color: white !important;
    background-color: #E9581E !important;
    border: #E9581E 1px solid !important;
}

.landing .btnOrange_border.active:hover {
    color: white !important;
    background-color: rgb(207, 76, 24) !important;
    border: #E9581E 1px solid !important;
}

/* Menu Bar Up */

.landing div.Logo img {
    width: 120px !important;
    height: 100% !important;
    margin: 3px 3px 3px 3px !important;
}

.landing div.Logo p {
    color: white !important;
    font-size: 18px !important;
}

/* 1° Section */

.landing div.imgBackground {
    position: fixed !important;
    z-index: -1 !important;
}

.landing div.imgBackground img {
    position: fixed !important;
    height: 100vh !important;
    width: auto !important;
    z-index: -2 !important;
}

.landing div.imgBackground div.colorBackground {
    position: absolute !important;
    height: 100vh !important;
    width: 100vw !important;
    background-color: #2E3340 !important;
    opacity: .9 !important;
    z-index: -1 !important;
}

.landing .positionRelative.imgs img {
    position: absolute;
    width: 80%;
    height: auto;
}

.landing .positionRelative.imgs img.img1 {
    z-index: 2;
    top: 35%;
    left: 0;
}

.landing .positionRelative.imgs img.img2 {
    z-index: 1;
    top: 25%;
    left: 10%;
}

.landing div.viewOne {
    color: white;
    height: 80vh;
}

.landing div.viewOne .text {
    text-align: justify;
}

/*.landing div.viewOne h1 {
    letter-spacing: 10px;
    font-weight: lighter;
}*/

/* 2° Section */

.landing .moveUp {
    transform: translateY(-5vh);
}

.landing .viewTwo {
    padding: 30px 15px;
}

.landing .viewTwo h1 {
    color: #707070;
    margin: 0;
    font-weight: normal;
}

.landing .viewTwo hr {
    margin: 5px 0;
    border: .5px solid rgb(180, 180, 180) !important;
    background-color: rgb(180, 180, 180) !important;
    width: 100%;
}

.landing .box_checkList {
    margin-bottom: 50px !important;
}

.landing .checkList .icon {
    fill: #E9581E;
    margin-right: 15px;
    height: 20px;
    width: 20px;
}

.landing .checkList h3 {
    font-weight: normal;
    color: #2E3340;
    margin: 10px 0;
    text-align: left;
}

.landing .imgPerson img {
    width: 100%;
    height: auto;
}

/* 3° Section */

.landing .viewThree {
    text-align: left;
    margin-bottom: 30px;
}

.viewThree h1,
.landing .viewThree h3 {
    color: #707070;
    margin: 0;
    font-weight: normal;
    text-align: justify;
    text-align-last: left;
}

.landing .viewThree .titlePaper {
    color: #3F444F;
    margin: 0;
    font-weight: normal;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.landing .viewThree p {
    margin: 10px 0;
    font-weight: normal;
    text-align: justify;
    text-align-last: left;
}

.landing .viewThree img {
    width: 100%;
    height: auto;
}

.landing .paperHover {
    transition: .3s all;
}

.landing .paperHover:hover {
    transform: translateY(-5px);
    -webkit-box-shadow: 0px 7px 10px -2px rgba(0, 0, 0, 0.20);
    -moz-box-shadow: 0px 7px 10px -2px rgba(0, 0, 0, 0.20);
    box-shadow: 0px 7px 10px -2px rgba(0, 0, 0, 0.20);
}

.landing .footer {
    background-color: #F5F5F5;
    border-top: 1px gray solid;
    padding: 25px 0;
}


.landing .Spacial .Mui-selected {
    border-radius: 10px !important;
    background-color: #E9581E !important;
}

.landing .Spacial .Mui-selected .MuiButton-label {
    color: white !important;
}

.landing .Spacial .MuiButton-label {
    color: #E9581E;
}

.landing .Spacial .MuiButton-root:hover {
    background-color: transparent;
}

.landing .Spacial .MuiToggleButton-root {
    margin: 0;
    border: solid 0px #E9581E;

    width: fit-content !important;
    width: -moz-fill-available !important;
    width: -webkit-fill-available !important;
}

.landing .Spacial .MuiGrid-root.MuiGrid-item.MuiGrid-grid-sm-12 {
    width: 90%;
}

.landing .Spacial .MuiToggleButtonGroup-root {
    justify-content: space-around !important;
    display: flex !important;
    flex: auto;
    width: 85% !important;
    font-weight: normal;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media (min-width: 992px) {
    .landing .pR {
        padding: 60px !important;
    }

    .landing .Spacial .MuiToggleButtonGroup-root {
        justify-content: space-around !important;
        display: flex !important;
        flex: auto;
        width: 100% !important;
        font-weight: normal;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}


@media (min-width: 768px) and (max-width: 991px) {
    .landing .pR {
        padding: 40px !important;
    }

    .landing .Spacial .MuiToggleButtonGroup-root {
        justify-content: space-around !important;
        display: flex !important;
        flex: auto;
        width: 90vh !important;
        font-weight: normal;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

@media (min-width: 576px) and (max-width: 767px) {
    .landing .pR {
        padding: 25px !important;
    }

    .landing .Spacial .MuiToggleButtonGroup-root {
        justify-content: space-around !important;
        display: flex !important;
        flex: auto;
        width: 80% !important;
        font-weight: normal;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

@media (min-width: 300px) and (max-width: 575px) {
    .landing .pR {
        padding: 25px !important;
    }

    .landing .Spacial .MuiToggleButtonGroup-root {
        justify-content: space-around !important;
        display: flex !important;
        flex: auto;
        width: 80% !important;
        font-weight: normal;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
/*=============================================
=              02. Section Spacing Css       =
=============================================*/
.section-space {
  /* Section Padding Css */
  /* Section Margin Css */
}

.section-space--ptb_120 {
  padding-top: 120px;
  padding-bottom: 120px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--ptb_120 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--ptb_120 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.section-space--pt_120 {
  padding-top: 120px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pt_120 {
    padding-top: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pt_120 {
    padding-top: 60px;
  }
}

.section-space--pb_120 {
  padding-bottom: 120px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pb_120 {
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pb_120 {
    padding-bottom: 60px;
  }
}

.section-space--ptb_100 {
  padding-top: 100px;
  padding-bottom: 100px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--ptb_100 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--ptb_100 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.section-space--pt_100 {
  padding-top: 100px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pt_100 {
    padding-top: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pt_100 {
    padding-top: 60px;
  }
}

.section-space--pb_100 {
  padding-bottom: 100px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pb_100 {
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pb_100 {
    padding-bottom: 60px;
  }
}

.section-space--ptb_90 {
  padding-top: 90px;
  padding-bottom: 90px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--ptb_90 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--ptb_90 {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.section-space--pt_90 {
  padding-top: 90px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pt_90 {
    padding-top: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pt_90 {
    padding-top: 40px;
  }
}

.section-space--pb_90 {
  padding-bottom: 90px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pb_90 {
    padding-bottom: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pb_90 {
    padding-bottom: 40px;
  }
}

.section-space--ptb_70 {
  padding-top: 70px;
  padding-bottom: 70px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--ptb_70 {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--ptb_70 {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.section-space--pt_70 {
  padding-top: 70px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pt_70 {
    padding-top: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pt_70 {
    padding-top: 30px;
  }
}

.section-space--pb_70 {
  padding-bottom: 70px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pb_70 {
    padding-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pb_70 {
    padding-bottom: 30px;
  }
}

.section-space--ptb_60 {
  padding-top: 60px;
  padding-bottom: 60px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--ptb_60 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--ptb_60 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.section-space--pt_60 {
  padding-top: 60px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pt_60 {
    padding-top: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pt_60 {
    padding-top: 60px;
  }
}

.section-space--pb_60 {
  padding-bottom: 60px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pb_60 {
    padding-bottom: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pb_60 {
    padding-bottom: 60px;
  }
}

.section-space--pt_40 {
  padding-top: 40px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pt_40 {
    padding-top: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pt_40 {
    padding-top: 30px;
  }
}

.section-space--pb_40 {
  padding-bottom: 40px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pb_40 {
    padding-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pb_40 {
    padding-bottom: 30px;
  }
}

.section-space--ptb_30 {
  padding-top: 30px;
  padding-bottom: 30px;
}

.section-space--mt_15 {
  margin-top: 15px;
}

.section-space--mt_20 {
  margin-top: 20px;
}

.section-space--mt_30 {
  margin-top: 30px;
}

.section-space--mt_40 {
  margin-top: 40px;
}

.section-space--mt_50 {
  margin-top: 50px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--mt_50 {
    margin-top: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--mt_50 {
    margin-top: 30px;
  }
}

.section-space--mt_60 {
  margin-top: 60px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--mt_60 {
    margin-top: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--mt_60 {
    margin-top: 30px;
  }
}

.section-space--mt_70 {
  margin-top: 70px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--mt_70 {
    margin-top: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--mt_70 {
    margin-top: 30px;
  }
}

.section-space--mt_80 {
  margin-top: 80px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--mt_80 {
    margin-top: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--mt_80 {
    margin-top: 30px;
  }
}

.section-space--mt_100 {
  margin-top: 100px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--mt_100 {
    margin-top: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--mt_100 {
    margin-top: 60px;
  }
}

.section-space--mt_120 {
  margin-top: 120px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--mt_120 {
    margin-top: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--mt_120 {
    margin-top: 60px;
  }
}

.section-space--mb_15 {
  margin-bottom: 15px;
}

.section-space--mb_20 {
  margin-bottom: 20px;
}

.section-space--mb_30 {
  margin-bottom: 30px;
}

.section-space--mb_40 {
  margin-bottom: 40px;
}

.section-space--mb_50 {
  margin-bottom: 50px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--mb_50 {
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--mb_50 {
    margin-bottom: 30px;
  }
}

.section-space--mb_60 {
  margin-bottom: 60px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--mb_60 {
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--mb_60 {
    margin-bottom: 30px;
  }
}

.section-space--mb_100 {
  margin-bottom: 100px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--mb_100 {
    margin-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--mb_100 {
    margin-bottom: 60px;
  }
}

.section-space--mb_120 {
  margin-bottom: 120px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--mb_120 {
    margin-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--mb_120 {
    margin-bottom: 60px;
  }
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-40 {
  margin-top: 40px;
}

@media only screen and (max-width: 767px) {
  .small-mt__10 {
    margin-top: 10px;
  }
  .small-mt__20 {
    margin-top: 20px;
  }
  .small-mt__30 {
    margin-top: 30px;
  }
  .small-mt__40 {
    margin-top: 40px;
  }
  .small-mb__30 {
    margin-bottom: 30px;
  }
  .small-mb__40 {
    margin-bottom: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tablet-mt__30 {
    margin-top: 30px;
  }
  .tablet-mt__40 {
    margin-top: 40px;
  }
  .tablet-mb__30 {
    margin-bottom: 30px;
  }
  .tablet-mb__40 {
    margin-bottom: 40px;
  }
}

.digital-store-hero__text {
  padding: 200px 0 90px;
}

@media only screen and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .digital-store-hero__text {
    padding: 100px 0 90px;
  }
}

.text-secondary{
    color: #002242;
}

.text-title{
    color: #183b51;
}

.text-primary{
    color: #638a9d;
}

.bg-started{
    background: #20242f;
}

.started-img{
    width: 120px;
}

.benefits-img{
    width: 80px;
}

/*========================================
=        20. Video Popup Css            =
==========================================*/
.single-popup-wrap {
  position: relative;
}

.video-link {
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.video-link .ht-popup-video.video-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}

.video-link .ht-popup-video.video-overlay .video-button__one {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.video-link .ht-popup-video.video-overlay .video-button__one .video-play {
  width: 60px;
  height: 60px;
  background: transparent;
  border: 4px solid #fff;
  border-radius: 50%;
  -webkit-transition: all 1s cubic-bezier(0, 0, 0.2, 1) !important;
  -o-transition: all 1s cubic-bezier(0, 0, 0.2, 1) !important;
  transition: all 1s cubic-bezier(0, 0, 0.2, 1) !important;
}

.video-link .ht-popup-video.video-overlay .video-button__one .video-play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  line-height: 1;
  margin-left: 1px;
}

.video-link .ht-popup-video.video-overlay .video-button__one .video-play-icon::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  border-top: 11px solid transparent;
  border-bottom: 11px solid transparent;
  border-left: 17px solid #fff;
}

.video-link .ht-popup-video.video-overlay .video-button__two {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.video-link .ht-popup-video.video-overlay .video-button__two .video-play {
  width: 72px;
  height: 72px;
  background: transparent;
  border: 6px solid #fff;
  border-radius: 50%;
  -webkit-transition: all 1s cubic-bezier(0, 0, 0.2, 1) !important;
  -o-transition: all 1s cubic-bezier(0, 0, 0.2, 1) !important;
  transition: all 1s cubic-bezier(0, 0, 0.2, 1) !important;
}

.video-link .ht-popup-video.video-overlay .video-button__two .video-play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  line-height: 1;
  margin-left: 1px;
}

.video-link .ht-popup-video.video-overlay .video-button__two .video-play-icon::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  border-top: 11px solid transparent;
  border-bottom: 11px solid transparent;
  border-left: 17px solid #fff;
}

.video-link .ht-popup-video.video-overlay .video-button__three {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.video-link .ht-popup-video.video-overlay .video-button__three .video-play {
  width: 120px;
  height: 120px;
  background: #ffffff;
  border: 6px solid #fff;
  border-radius: 50%;
  -webkit-transition: all 1s cubic-bezier(0, 0, 0.2, 1) !important;
  -o-transition: all 1s cubic-bezier(0, 0, 0.2, 1) !important;
  transition: all 1s cubic-bezier(0, 0, 0.2, 1) !important;
}

.video-link .ht-popup-video.video-overlay .video-button__three .video-play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  line-height: 1;
  margin-left: 1px;
}

.video-link .ht-popup-video.video-overlay .video-button__three .video-play-icon::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  border-top: 11px solid transparent;
  border-bottom: 11px solid transparent;
  border-left: 17px solid #fff;
  border-top-width: 12px;
  border-bottom-width: 12px;
  border-left-width: 19px;
  border-left-color: #20242f;
}

.video-link .ht-popup-video.video-overlay .video-button__four {
  position: absolute;
  top: 50%;
  left: 100%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

@media only screen and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 1200px) and (max-width: 1499px) {
  .video-link .ht-popup-video.video-overlay .video-button__four {
    left: 50%;
  }
}

.video-link .ht-popup-video.video-overlay .video-button__four .video-play {
  width: 120px;
  height: 120px;
  background: #ffffff;
  border: 6px solid #fff;
  border-radius: 50%;
  -webkit-transition: all 1s cubic-bezier(0, 0, 0.2, 1) !important;
  -o-transition: all 1s cubic-bezier(0, 0, 0.2, 1) !important;
  transition: all 1s cubic-bezier(0, 0, 0.2, 1) !important;
  -webkit-box-shadow: 0 20px 30px rgba(0, 0, 0, 0.07);
          box-shadow: 0 20px 30px rgba(0, 0, 0, 0.07);
}

.video-link .ht-popup-video.video-overlay .video-button__four .video-play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  line-height: 1;
  margin-left: 1px;
}

.video-link .ht-popup-video.video-overlay .video-button__four .video-play-icon::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  border-top: 11px solid transparent;
  border-bottom: 11px solid transparent;
  border-left: 17px solid #fff;
  border-top-width: 12px;
  border-bottom-width: 12px;
  border-left-width: 19px;
  border-left-color: #5945e6;
}

.video-link .ht-popup-video.video-overlay .video-button__five {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.video-link .ht-popup-video.video-overlay .video-button__five .video-play {
  width: 78px;
  height: 78px;
  background-image: -webkit-linear-gradient(311deg, #20242f 0%, #638a9d 100%);
  background-image: -o-linear-gradient(311deg, #20242f 0%, #638a9d 100%);
  background-image: linear-gradient(-221deg, #20242f 0%, #638a9d 100%);
  -webkit-box-shadow: 0 2px 41px 0 rgba(91, 99, 254, 0.36);
          box-shadow: 0 2px 41px 0 rgba(91, 99, 254, 0.36);
  border-radius: 50%;
  -webkit-transition: all 1s cubic-bezier(0, 0, 0.2, 1) !important;
  -o-transition: all 1s cubic-bezier(0, 0, 0.2, 1) !important;
  transition: all 1s cubic-bezier(0, 0, 0.2, 1) !important;
  -webkit-box-shadow: 0 20px 30px rgba(0, 0, 0, 0.07);
          box-shadow: 0 20px 30px rgba(0, 0, 0, 0.07);
}

.video-link .ht-popup-video.video-overlay .video-button__five .video-play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  line-height: 1;
  margin-left: 1px;
}

.video-link .ht-popup-video.video-overlay .video-button__five .video-play-icon::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  border-top: 11px solid transparent;
  border-bottom: 11px solid transparent;
  border-left: 17px solid #fff;
  border-top-width: 12px;
  border-bottom-width: 12px;
  border-left-width: 19px;
  border-left-color: #ffffff;
}

.video-link .ht-popup-video.video-overlay .video-mark {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateY(-50%, -50%);
      -ms-transform: translateY(-50%, -50%);
          transform: translateY(-50%, -50%);
  pointer-events: none;
}

.video-link .ht-popup-video.video-overlay .video-mark .wave-pulse {
  width: 1px;
  height: 0;
  margin: 0 auto;
}

.video-link .ht-popup-video.video-overlay .video-mark .wave-pulse::after, .video-link .ht-popup-video.video-overlay .video-mark .wave-pulse::before {
  opacity: 0;
  content: '';
  display: block;
  position: absolute;
  width: 200px;
  height: 200px;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  border: 3px solid #20242f;
  -webkit-animation: zoomBig 3.25s linear infinite;
          animation: zoomBig 3.25s linear infinite;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.video-link .ht-popup-video.video-overlay .video-mark .wave-pulse::before {
  -webkit-animation-delay: .75s;
          animation-delay: .75s;
}

.video-link:hover .video-play {
  -webkit-transform: scale3d(1.15, 1.15, 1.15);
          transform: scale3d(1.15, 1.15, 1.15);
}

@-webkit-keyframes zoomBig {
  0% {
    -webkit-transform: translate(-50%, -50%) scale(0);
            transform: translate(-50%, -50%) scale(0);
    opacity: 1;
    border-width: 3px;
  }
  40% {
    opacity: .5;
    border-width: 2px;
  }
  65% {
    border-width: 1px;
  }
  100% {
    -webkit-transform: translate(-50%, -50%) scale(1);
            transform: translate(-50%, -50%) scale(1);
    opacity: 0;
    border-width: 1px;
  }
}

@keyframes zoomBig {
  0% {
    -webkit-transform: translate(-50%, -50%) scale(0);
            transform: translate(-50%, -50%) scale(0);
    opacity: 1;
    border-width: 3px;
  }
  40% {
    opacity: .5;
    border-width: 2px;
  }
  65% {
    border-width: 1px;
  }
  100% {
    -webkit-transform: translate(-50%, -50%) scale(1);
            transform: translate(-50%, -50%) scale(1);
    opacity: 0;
    border-width: 1px;
  }
}

@keyframes zoomBig {
  0% {
    -webkit-transform: translate(-50%, -50%) scale(0);
            transform: translate(-50%, -50%) scale(0);
    opacity: 1;
    border-width: 3px;
  }
  40% {
    opacity: .5;
    border-width: 2px;
  }
  65% {
    border-width: 1px;
  }
  100% {
    -webkit-transform: translate(-50%, -50%) scale(1);
            transform: translate(-50%, -50%) scale(1);
    opacity: 0;
    border-width: 1px;
  }
}

.border-card{
  border-radius: 30px;
}

.border-paper{
  border-radius: 10px;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.landing-container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .landing-container {
    max-width: 540px !important;
  }
}

@media (min-width: 768px) {
  .landing-container {
    max-width: 720px !important;
  }
}

@media (min-width: 992px) {
  .landing-container {
    max-width: 960px !important;
  }
}

@media (min-width: 1279px) {
  .landing-container {
    max-width: 1140px !important;
  }
  .landing-TextR{
    margin-right: 0px !important;
    max-width: 570px !important;
  }
  .landing-TextL{
    margin-left: 0px !important;
    max-width: 910px !important;
  }
}

@media (min-width: 1600px) {
  .landing-container {
    max-width: 1480px !important;
  }
  .landing-TextR{
    margin-right: 0px !important;
    max-width: 745px !important;
  }
  .landing-TextL{
    margin-left: auto !important;
    max-width: 1000px !important;
  }
}  