.react-datepicker-popper {
    z-index: 3 !important;
}
.react-datepicker__month .react-datepicker__month-text, .react-datepicker__month .react-datepicker__quarter-text{
    padding: 10px;
}
.react-datepicker__year .react-datepicker__year-text{
    padding: 10px;
}
.react-datepicker-wrapper{
    width: 100%;
}